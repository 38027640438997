<template>
  <v-dialog :value="value" @change="handleChange" persistent max-width="800px">
    <v-card>
      <v-app-bar flat>
        <v-card-title class="px-0">{{ dialogTitle }}</v-card-title>

        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form
        @submit.prevent="save"
        class="pa-2"
      >
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click="openSelectPhotosDialog">
            <v-icon>mdi-plus</v-icon>{{ $capitalize($tc("model.photographic_report_add_photos_button")) }}
          </v-btn>
        </v-card-actions>
        <empty-state 
          :when="form.photos.length === 0"
          :message="
            $capitalize($tc('model.photographic_report_no_photos_message'))
          "
        >
          <v-alert type="warning" class="mt-2" v-if="report_too_big_warning_EPS">{{
            report_too_big_warning_EPS
          }}</v-alert>
          <v-list subheader>
            <v-virtual-scroll
              :bench="1"
              :items="form.photos"
              :item-height="95"
              :height="virtualScrollerHeight"
              v-resize="onResize"
            >
              <template v-slot="{ item: photo, index }">
                <v-list-item
                  :key="photo.id"
                  @click="previewPhoto(photo)"
                  :input-value="recently_order_photo_index == index"
                >
                  <v-list-item-icon>
                    <v-badge
                      overlap
                      bordered
                      :content="index + 1"
                    >
                      <v-avatar size="60" tile>
                        <v-img
                          :alt="photo.description"
                          :src="photo.public_url"
                        />
                      </v-avatar>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-icon class="mr-2">
                    <v-btn icon @click.stop.prevent="orderPhotos(index, 'up')">
                      <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon @click.stop.prevent="orderPhotos(index, 'down')">mdi-chevron-down</v-icon>  
                    </v-btn>            
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="photo.name" />
                    <v-list-item-subtitle v-text="photo.category.description" />
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click.stop.prevent="handleDeletePhoto(photo)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < form.photos.length - 1"
                  :key="index"
                ></v-divider>
            </template>
            </v-virtual-scroll>
          </v-list>
        </empty-state>
        <v-card-actions>
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-subtitle class="text--secondary">{{ form_photo_count }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text--secondary">{{ size_report_message_in_mb }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-spacer />
          <v-btn outlined color="primary" type="submit" :loading="saving_report">
            {{ $capitalize($tc("model.photographic_report_save_button")) }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <SelectPhotosDialog
      v-model="select_photos_dialog"
      :photos="photos"
      :selected_photos="form.photos"
      @submit="submitSelectedPhotosDialog"
    />

    <file-dialog
      v-model="file_preview"
      :attachment="preview_photo"
      @updateFile="updateFile"
      @deleteFile="deleteFile"
    />
  </v-dialog>
</template>

<script>
import { formatDate } from '@/tools/date';
import { formatDecimal } from '@/tools/number';
import { store as storeReport, update as updateReport } from "@/services/photographic_reports";
import EmptyState from "@/components/EmptyState";
import SelectPhotosDialog from '@/components/Lead/PhotographicReport/SelectPhotosDialog';
import FileDialog from '@/components/FileDialog';

export default {
  name: "photographic-report-dialog",
  components: { EmptyState, SelectPhotosDialog, FileDialog },
  props: {
    value: Boolean,
    report: Object,
    photos: Array
  },
  data: () => ({
    windowHeight: 0,

    saving_report: false,
    form: {
      photos: []
    },

    // Select Photos Dialog
    select_photos_dialog: false,

    //File Dialog
    file_preview: false,
    preview_photo: {},

    //Order Photos
    recently_order_photo_index: null
  }),
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },

    deleteFile(file_deleted) {
      this.photos = this.photos.filter((preview_photo) => {
        return preview_photo.id !== file_deleted
      });
      this.file_preview = false;
    },
    updateFile(file_changed) {
      this.form.photos = this.form.photos.map((file_for_change) => {
        return file_for_change.id === file_changed.id
          ? { ...file_changed }
          : file_for_change;
      });
    },
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      this.resetForm();
    },
    resetForm() {
      this.form = {
        photos: []
      };
    },
    loadForm() {
      this.form = {
        ...this.form,
        ...this.report
      };
    },
    setErrors(data) {
      if (data) {
        this.$store.commit('sendMessage', {
          text: data.message,
          color: 'red'
        });
      }    
    },
    async save() {
      this.saving_report = true;
      try {
        if (this.editMode) {
          await this.update();
        } else {
          await this.create();
        }
      } catch (e) {
        this.setErrors(e?.response?.data);
      } finally {
        this.saving_report = false;
      }
    },
    async create() {
      await storeReport(this.lead?.id, {
        photographic_report: this.form
      });
      this.$listeners.submit();
      this.handleClose();
    },
    async update() {
      await updateReport(this.lead?.id, this.report?.id, {
        photographic_report: this.form
      });
      this.$listeners.submit();
      this.handleClose();
    },

    orderPhotos(index, movement) {
      const element = this.form.photos[index];
      const newIndex = movement == 'up' ? (index - 1) : (index + 1);
      const surpassed_limit = newIndex > (this.form.photos.length - 1);

      if (newIndex < 0 || surpassed_limit) return;

      this.form.photos.splice(index, 1);
      this.form.photos.splice(newIndex, 0, element);
      this.recently_order_photo_index = newIndex;

      setTimeout(() => {
        this.recently_order_photo_index = null;
      }, 2000);
    },

    // Select Photos Dialog
    openSelectPhotosDialog() {
      this.select_photos_dialog = true;
    },
    submitSelectedPhotosDialog(photos)
    {
      photos.forEach(photo => {
        const already_selected = this.form.photos.some(rec => rec.id == photo.id);
        if (!already_selected) {
          this.form.photos.push(photo);
        }
      });
    },

    // File Preview
    previewPhoto(photo) {
      this.preview_photo = photo;
      this.file_preview = true;
    },
    setFormPhoto(photo) {
      this.form.photos = this.form.photos.map(rec => {
        if (rec.id == photo.id) {
          return {
            ...rec,
            ...photo
          };
        }

        return rec;
      });    
    },
    handlePhotoChange(photo) {
      this.$store.commit('setLeadAvailableReportPhoto', photo);
      this.setFormPhoto(photo);
      this.preview_photo = photo;
      this.file_preview = false;
    },
    handleDeletePhoto(confirm_delete_photo) {
      this.form.photos = this.form.photos.filter(photo => photo.id != confirm_delete_photo?.id);
    }
  },
  computed: {
    editMode() {
      return this.report?.id != null;
    },
    form_photo_count() {
      return this.form.photos.length + ' ' + this.$capitalize(this.$tc('model.reports_selected_photos_dialog'));
    },
    size_report_message_in_mb() {
      const in_mb = this.probable_report_size / 1024;
      return formatDecimal(in_mb, 2) + ' MB';
    },
    probable_report_size() {
      const sum_size = _.sumBy(this.form.photos, photo => photo.size / 1024);
      const report_size = sum_size > 0 
        ? 2 * 1024 
        : 0;
      return sum_size + report_size;
    },
    report_too_big_warning_EPS() {
      const limit_size = 10 * 1024;

      if (this.lead.is_from_eps && this.probable_report_size > limit_size) {
        return "O relatório provavelmente excederá o tamanho limite de envio de 10 MB para a EPS. Se possível remover algumas imagens ou dividir o relatório em partes.";
      }      

      return null;
    },
    dialogTitle() {
      if (this.editMode)
        return `Photographic Report ${formatDate(this.report.created_at)}`;

      return this.$capitalize(this.$tc("model.photographic_report_new_report_title"));
    },
    lead() {
      return this.$store.getters.getLead;
    },
    virtualScrollerHeight: function () {
      return this.windowHeight * 0.4;
    },
  },
  created() {
    this.loadForm();
  },
  watch: {
    report: function(value) {
      if (value) {
        this.loadForm();
      }
    }
  },
  mounted() {
    this.onResize();
  },
}
</script>

<style>

</style>