import Vue from "vue";


export async function index(lead_id) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_id}/photographic_reports`);

  return response.data;
}

export async function store(lead_id, payload) {
  const response = await Vue.prototype.$http.post(`/leads/${lead_id}/photographic_reports`, payload);

  return response.data;
}

export async function update(lead_id, report_id, payload) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_id}/photographic_reports/${report_id}`, payload);

  return response.data;
}

export async function retry(lead_id, report_id) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_id}/photographic_reports/${report_id}/retry`);

  return response.data;
}

export async function getAvailablePhotosForReport(lead_id) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_id}/photos_for_report`);

  return response.data;
}
