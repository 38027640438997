<template>
  <v-card flat>
    <v-card-title>
      {{ $capitalize($tc("model.photographic_report_photo_reports_title")) }}
      <v-spacer />
      <ScopeProvider scope="leads.photographic_report.add">
        <v-btn icon @click="fetchPhotographicReports(lead.id)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn outlined color="primary" @click="openNewPhotographicReport">
          <v-icon>mdi-plus</v-icon>
          {{ $capitalize($tc("model.photographic_report_new_photo_button")) }}
        </v-btn>
      </ScopeProvider>
    </v-card-title>
    <v-data-table
      :headers="headers_photographic_reports"
      :items="photographic_reports"
      :loading="fetching_photographic_reports"
      hide-default-footer
    >

      <template v-slot:header.attachment.description>
        {{ $capitalize($tc("model.photographic_report_report_column_table", 1)) }}
      </template>

      <template v-slot:header.photos>
        {{ $capitalize($tc("model.photographic_report_photos_column_table", 1)) }}
      </template>

      <template v-slot:header.integration>
        {{ $capitalize($tc("model.photographic_report_integration_column_table", 1)) }}
      </template>

      <template v-slot:header.actions>
        {{ $capitalize($tc("model.photographic_report_action_column_table", 1)) }}
      </template>

      <template v-slot:header.created_at>
        {{ $capitalize($tc("model.photographic_report_created_in_column_table", 1)) }}
      </template>

      <template v-slot:item.integration="{ item }">
        <span v-if="item.attachment && item.attachment.external_reference">
          <v-icon color="green">mdi-check</v-icon> {{ $capitalize($tc("model.photographic_report_integrated_info")) }}
        </span>
        <span v-else>
          {{ $capitalize($tc("model.photographic_report_not_integrated_info")) }}
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ getFormatedDate(item.created_at) }}
      </template>
      <template v-slot:item.photos="{ item }">
        {{ item.photos.length }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn 
          v-if="item.status && item.status.canonical == 'failed'" 
          icon 
          @click="retryFailedReport(item)"
          :loading="retrying_reports.includes(item.id)"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn 
          v-if="$store.getters['auth/getScope']('leads.photographic_report.edit')" 
          icon 
          @click="editPhotographicReport(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn 
          icon 
          :href="item.attachment && item.attachment.public_url" 
          target="_blank"
          :disabled="item.attachment == null"
        >
          <v-icon>mdi-file-find</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <photographic-report-dialog
      v-model="photographic_report_dialog"
      :report="current_photographic_report"
      :photos="lead.available_report_photos"
      @cancel="cancelPhotographicReportDialog"
      @submit="handlePhotographicReportSubmit"
    />
  </v-card>
</template>

<script>
import { 
  index as indexPhotographicReports, 
  getAvailablePhotosForReport ,
  retry
} from '@/services/photographic_reports';
import { formatDateTimeWithDuration } from "@/tools/date";

import PhotographicReportDialog from '@/components/Lead/PhotographicReport/ReportDialog';
import ScopeProvider from "@/components/ScopeProvider";

export default {
  components: { PhotographicReportDialog, ScopeProvider },
  data: function() {
    return {
      fetching_photographic_reports: false,
      photographic_reports: [],
      headers_photographic_reports: [
        {
          text: "Criado em",
          value: "created_at",
        },
        {
          text: "Status",
          value: "status.text"
        },
        {
          text: "Fotos",
          value: "photos"
        },
        {
          text: "Integração",
          value: "integration"
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 180,
          align: 'end'
        }
      ],

      //Available Photos For Report
      fetching_available_photos: false,

      //Photographic Report Dialog
      photographic_report_dialog: false,
      current_photographic_report: {},

      retrying_reports: [],
    };
  },
  methods: {
    getFormatedDate(date) {
      return formatDateTimeWithDuration(date);
    },
    async fetchPhotographicReports(lead_id) {
      this.fetching_photographic_reports = true;
      try {
        const response = await indexPhotographicReports(lead_id);
        this.photographic_reports = response;
      } catch(e) {
        this.$store.commit('sendMessage', {
          text: 'Não foi possível carregar os relatórios fotográficos',
          color: 'red'
        });
      } finally {
        this.fetching_photographic_reports = false;
      }
    },

    //Available Photos For Report
    async fetchAvailablePhotosForReport(lead_id) {
      this.fetching_available_photos = true;
      try {
        const response = await getAvailablePhotosForReport(lead_id);
        this.$store.commit('setLead', {
          ...this.lead,
          available_report_photos: response
        });
      } catch(e) {
        this.$store.commit('sendMessage', {
          text: 'Não foi possível carregar as fotos para cadastrar nos relatórios fotográficos',
          color: 'red'
        });
      } finally {
        this.fetching_available_photos = false;
      }
    },

    async retryFailedReport(report) {
      this.retrying_reports.push(report.id);
      try {
        await retry(this.lead?.id, report.id);        
        this.fetchPhotographicReports(this.lead?.id);
      } catch(e) {
        this.$store.commit('sendMessage', {
          text: 'Não foi possível realizar a nova tentativa de geração do relatório',
          color: 'red'
        });
      } finally {
        this.retrying_reports = this.retrying_reports.filter(rec => rec !== report.id);
      }
    },

    //Photographic Report Dialog
    openNewPhotographicReport() {
      this.current_photographic_report = {};
      this.photographic_report_dialog = true;
    },
    editPhotographicReport(photographic_report) {
      this.current_photographic_report = { ...photographic_report };
      this.photographic_report_dialog = true;
    },
    handlePhotographicReportSubmit() {
      this.fetchPhotographicReports(this.lead?.id);
      this.fetchAvailablePhotosForReport(this.lead?.id);
      this.cancelPhotographicReportDialog();
    },
    cancelPhotographicReportDialog() {
      this.photographic_report_dialog = false;
      this.current_photographic_report = {};
    },
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
  },
  created() {
    if (this.lead?.id) {
      this.fetchPhotographicReports(this.lead?.id);
      this.fetchAvailablePhotosForReport(this.lead?.id);
    }
  },
  watch: {
    lead: function(value, oldValue) {
      const old_lead_id = oldValue?.id;
      const lead_id = value?.id;

      if (old_lead_id == null && lead_id != null) {
        this.fetchPhotographicReports(lead_id);
        this.fetchAvailablePhotosForReport(lead_id);
      }
    },
    retrying_reports(value, old) {
      console.log('retrying_reports', value);
      console.log('oldretrying_reports', old);
    }
  }
}
</script>

<style>

</style>